
import React, { useEffect, useState } from 'react';
import store from '../actions/store/store';
import { H1 } from '@blueprintjs/core';
import { useLocation, useNavigate } from 'react-router-dom';

export const Sites= ()=>{
    const [sitesx,setsitesx]=useState([] as any)
    const [boolx,setBoolx]=useState(false)
    const location = useLocation()
    const navigate= useNavigate();
    useEffect(()=>{ 

        if(location.state!==null){      
            setsitesx(location.state.x.sites)
            }
            
    })

    const mobileSceenOpen=(x:any)=>{
        navigate(x.path,{state:{business:location.state.x,site:x}})
    }

    return(<div>
        { sitesx.map((x:any,i:any)=>(
            <H1 key={i} className='p-10H-1' onClick={()=>mobileSceenOpen(x)}>{x.path}</H1>
        ))
        }
    </div>)
}