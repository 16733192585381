import { Button, Colors, H2, H3, Icon, Spinner } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { differenceInYears } from "date-fns";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { firebase } from '../global/firebase';
import { errorInOperation } from '../kiosklog/ServerLogging';
import { strings } from '../actions/string';
import { UserAction } from '../actions/urserAction';
import { ExternalCss } from '../components/ExternalCss';
export const Vehicle=()=>{

    const location = useLocation();
    const routes=location.state.site.routes;
    const [businesses,setBusinesses]=useState(null as any)
    const [site,setsite]=useState(null as any)
    const [restartCheckIn,setrestartCheckIn]=useState(false)
    const [IsUpdating,setIsUpdating]=useState(location.state.IsUpdating)
    const navigate= useNavigate();
    const [vehicles,setvehicles]=useState([] as any);
    const [Nextdisable,setNextdisable]=useState(false)
    const [AddVehicle,setAddVehicle]=useState(false)
    const language  =location.state.language==='en' ? strings.en : strings.esp;
    useEffect(()=>{
        setBusinesses(location.state.business)            
        setsite(location.state.site)
        if(routes.vehicles.enabled===false || (routes.vehicles.alwaysShow===false && location.state.user!==false && location.state.IsUpdating===false) || location.state.optOut===true ){
            NextfinishButton()
        }

        if(vehicles.length<1){               
            let WhereCondition = where('siteId','==',location.state.site.siteId);
            if('s3Id' in location.state.business && location.state.business.s3Id!==null && location.state.business.s3Id!==''){
                WhereCondition = where('siteId','==',null)
              }else if('urgId' in location.state.business && location.state.business.urgId!==null && location.state.business.urgId!==''){
                WhereCondition = where('siteId','==',null)
              } 
             getDocs(query(collection(firebase,'vehiclePicks'),where('businessId','==',location.state.business.businessId),WhereCondition,where('mobile','==',location.state.mobile))).then(async(Allpicks:any)=>{                 
                if(!Allpicks.empty){                        
                        const arr:any=[];                      
                        Allpicks.docs.map((x:any)=>{
                                const data= x.data();
                                data.id =x.id
                                    arr.push(data);
                        });
                        setvehicles(arr)
                    }

            })
            
        }
    })

      const NextfinishButton=async()=>{
            const site = location.state.site;
            const business = location.state.business;
            const user = location.state.user;
            const firstName= location.state.firstName;
            const lastName=location.state.lastName;
            const mobile =location.state.mobile;
            const zip=location.state.zip;
            const dateofbirth= location.state.dateofbirth;
            const lang= location.state.language;
            const optOut=location.state.optOut;
            const path = '/'+business.path+'/'+site.path
            if(location.state.IsUpdating){
                setNextdisable(true)
                await UserAction('updateUser',{userId:location.state.userId,vehicles
              })
                location.state.userId = location.state.userId;
                location.state.IsUpdating=false; 
                location.state.IsUpdated=true;
                setNextdisable(false)     
                navigate(path+'/thanks',location)
              }else{ 
            navigate(path+'/signature',{state:{mobile,user,business,site,firstName,lastName,zip,dateofbirth,vehicles,language:lang,optOut,IsUpdating:false}})
              }
            
    }
      
    const restartCheckInfun=async()=>{
        setrestartCheckIn(true)
        const  error = { 
               associated_data:JSON.stringify('restart checkIn from vehicle screen'),
               class_name:'vehicle',
               error_message:'restart checkIn',
               error_type:'component error',
               exception_message:JSON.stringify('restart checkIn'),
               file_name:'vehicle.tsx',
               function_name:'vehicle-Component',
               }
         const errorInOperationx = await errorInOperation(error);
         if(errorInOperationx){
         const path = '/'+businesses.path+'/'+site.path  
         navigate(path,{state:{business:location.state.business,site:location.state.site}})
           }
   }
   
const addNewVehicle=()=>{
    console.log('okk');
    setAddVehicle(true)
}

const addVehicleInState=(pick:any)=>{    
    vehicles.push(pick)    
    setvehicles(vehicles);
    setAddVehicle(false)
}
const DeleteVehicle=async(x:any)=>{
await deleteDoc(doc(firebase, "vehiclePicks", x.id)).then(()=>{
    console.log('success fully delete pick');
   const vehies=  vehicles.filter((r:any)=>r.id!==x.id)  
    setvehicles(vehies);

}).catch((e:any)=>{
    console.log(e);
    
});


}
const locationcheck = window.location.href.includes('wilberts');
    return(
        businesses!==null && site!==null ?<div  className='main-container' style={{ 
    display:'block',
    background:site.kioskUiBackgroundColor!==undefined && site.kioskUiBackgroundColor!==null && site.kioskUiBackgroundColor!=='' ?  site.kioskUiBackgroundColor : businesses.theme.background
        ,height:'100vh'}}>
              <ExternalCss/>
            <div className="">
                <h1 className="enternameTitle titleforwilbert " style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>{language.receiveNotice}</h1>
                <button  disabled={restartCheckIn} style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : 'rgb(226, 50, 41)'}} onClick={()=>restartCheckInfun()} className='optoutbtn restartCheckinButton' >{restartCheckIn ? <ScaleLoader  width={10} height={15} color="#fff" /> :language.restartCheckIn}</button>
            </div>  


            {locationcheck && vehicles.length<=0 && !AddVehicle? <div>
                {routes.name.optional===true  && !IsUpdating  ?
                   <button   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>NextfinishButton()} className='nextbuttoncss vehicle-btn-2324' >{language.NoThanks}</button>:''}
             
                    <button disabled={Nextdisable} style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>NextfinishButton()} className='nextbuttoncss vehicle-btn-2324' >{IsUpdating ? language.update :language.NextFinish}</button>
                    <p style={{fontSize:'16px'}}><b>Or</b></p>
           <button  style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>addNewVehicle()} className='backgroudAddVehicleColor nextbuttoncss vehicle-btn-2324' >{language.addnewvehicle}</button>

           </div>:''}           
       

           {!AddVehicle ? 
           <span>
            <div className="css-1id8coj"
            
            >
                {vehicles.length<1?<div className="css-11gkdzi">
                    <p className="css-z0ieus">{language.titlevehicle}</p>
                    <p className="css-z0ieus">{language.titlesubvehicle}</p>
                </div>:
                 <table className='picksList' border={1} >
                 <tbody>
                {vehicles.map((x:any,i:any)=>(
                 <tr key={i}>
                 <td>{x.yearMin}-{x.yearMax}</td>
                 <td>{x.make}</td>
                 <td>{x.model}</td>
                 <td><Button intent='danger' className='crossbuttoncss'><Icon size={20} onClick={()=>DeleteVehicle(x)} icon='cross'  intent='danger'/></Button></td>
                 </tr>
                 ))}
                 </tbody>
             </table>
                }
            </div>
            <br/>
            {!locationcheck ? 
            <button  style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>addNewVehicle()} className='backgroudAddVehicleColor nextbuttoncss' >{language.addnewvehicle}</button>
            :''}

{locationcheck && vehicles.length>0 ? 
            <button  style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>addNewVehicle()} className='backgroudAddVehicleColor nextbuttoncss' >{language.addnewvehicle}</button>
            :''}

            <br/> <br/> <br/>
           {locationcheck && vehicles.length>0 ? <div style={{display:'flex'}} className="centerDivName">
            {routes.name.optional===true  && !IsUpdating  ?   <button   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{language.NoThanks}</button>:''}
            
                    <button disabled={Nextdisable} style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{IsUpdating ? language.update :language.NextFinish}</button>
            </div>:locationcheck===false ?
            <div style={{display:'flex'}} className="centerDivName">
            {routes.name.optional===true  && !IsUpdating  ?   <button   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{language.NoThanks}</button>:''}
                 
                    <button disabled={Nextdisable} style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{IsUpdating ? language.update :language.NextFinish}</button>
            </div>
            :''
            }
          </span>:
         <AddNewVehicle  state={location} cancleAddVehicle={()=>setAddVehicle(false)} VehicleAdd={(pick:any)=>addVehicleInState(pick)}/>
         }
       
        </div>:<p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>
    )
    
    
}



export const AddNewVehicle=(data:any)=>{
    const site = data.state.state.site;
    const siteId= data.state.state.site.siteId;
    const businessId = data.state.state.business.businessId;
    const business = data.state.state.business;
    const mobile = data.state.state.mobile;
    const firstName = data.state.state.firstName;
    const language  =data.state.state.language==='en' ? strings.en : strings.esp;
    const date = new Date();
    const [minYear,setMinYear]=useState(0);
    const [maxYear,setmaxYear]=useState(0);
    const [Model,setModel]=useState('');
    const [Make,setMake]=useState('');
    const [Models,setModels]=useState([] as any)
    const [addVehicledisable,setaddVehicledisable]=useState(false)
    const years: number[] = [];
    const Colorsx =business.theme!==undefined && business.theme.colors!==undefined && business.theme.colors.secondary!==undefined ? business.theme.colors.secondary:'#fff';
   
    const currentYear = new Date().getFullYear();
    for (let i = 1960; i <= currentYear; i++) { years.push(i); }


    const minYearChange=(e:any)=>{
        setMinYear(e.target.value);
        setModel('')
        getModels(e,'min')
    }
    const maxYearChange=(e:any)=>{
        getModels(e,'max')
        setModel('')
        setmaxYear(e.target.value)
    }
    const getModels=(e:any,fiels:string)=>{       
        const make=fiels==='make'? e.target.value : Make;
        const min=fiels==='min'? e.target.value : minYear;
        const max=fiels==='max'? e.target.value : maxYear;
        ModelsFetchfunction(make,min,max)
    }

   const ModelsFetchfunction=async(make:any,min:any,max:any)=>{
    setModels([])  
    if(make!=='' && min!==0 && max!==0 ){                    
        const AllModels =  await getDocs(query(collection(firebase,'vehicles'),where('year','>=',parseInt(min)),where('year','<=',parseInt(max)),where('make','==',make)))
        const arr:any =[]
        
        if(!AllModels.empty){
            console.log(AllModels.size,'size');            
            AllModels.docs.map((x:any)=>{
                let smodel= x.data().model
                if(make==='BMW' || make==='MERCEDES-BENZ'){
                 smodel= x.data().groupName;  
                }             
                arr.push(smodel)
            });
            const unique =arr.filter((v: any,i: any,a: string | any[])=>a.indexOf(v)==i)
            const sorted = unique.sort();
            
            setModels(sorted)
        }
    }
   } 

const AddNewVehiclePick=async()=>{
    setaddVehicledisable(true);
    const array:any=[]  
    if(Make==='BMW' || Make==='MERCEDES-BENZ'){
        console.log(Make,Model,minYear,maxYear,'oooooo');
        
     const datas=await getDocs(query(collection(firebase,'vehicles')
                ,where('make','==',Make)
                ,where('groupName','==',Model)
                ,where('year','>=',parseInt(minYear+'',10))
                ,where('year','<=',parseInt(maxYear+'',10)))); 
            datas.docs.map((x:any)=>{
              array.push(x.data().model)
            }) 
    }else{
        array.push(Model)
    }

    let siteIdf = siteId;
    try {
        const business = await getDoc(doc(collection(firebase,'businesses'),businessId));
        if(business.exists()){
          const buisenessx = business.data()          
          if('s3Id' in buisenessx && buisenessx.s3Id!==null && buisenessx.s3Id!==''){
            siteIdf=null
          }else if('urgId' in buisenessx && buisenessx.urgId!==null && buisenessx.urgId!==''){
            siteIdf=null
          }
         console.log(siteIdf,'site id for vehicle picks');
        }
    } catch (error) {
        console.log(error);
    }
    const pick:any={        
        businessId,
        combined:Make+' '+Model,
        firstName,
        language:data.state.state.language,
        make:Make,
        mobile,
        modelsGroup:array,
        model:Model,
        siteId:siteIdf,
        textSent:false,
        timestamp:date,
        userId:null,
        yearMax:maxYear,
        yearMin:minYear,
    }

   const docid= await addDoc(collection(firebase, "vehiclePicks"), pick);
    console.log(docid.id);   
    pick.id = docid.id
    data.VehicleAdd(pick)   
    setaddVehicledisable(false);

}


    return (
        <div style={{display:'block'}} >
    <table style={{margin:'auto',width:'50vw'}}>
    <tbody>
    <tr>
            <th className='css-1ldd5ho' style={{color:site.kioskUiTextColor!==undefined && site.kioskUiTextColor!==null && site.kioskUiTextColor!=='' ? site.kioskUiTextColor : Colorsx}}>{language.MinYear}</th>
            <td>
                <select className='css-17vg6hn' onChange={(e)=>minYearChange(e)}>
                    <option>{language.select} {language.MinYear}</option>
                    {years.map((y:any,i:any)=>(
                        <option key={i} value={y}>{y}</option>
                    ))}
                </select>
            </td>
        </tr>
        <tr>
            <th  className='css-1ldd5ho' style={{opacity:minYear>0?1 :0.5,color:site.kioskUiTextColor!==undefined && site.kioskUiTextColor!==null && site.kioskUiTextColor!=='' ? site.kioskUiTextColor : Colorsx}} >{language.MaxYear}</th>
            <td>
                <select disabled={minYear>0 ? false : true} className='css-17vg6hn' onChange={(e)=>maxYearChange(e)}>
                    <option>{language.select} {language.MaxYear}</option>
                    {years.map((y:any,i:any)=>(
                    y>=minYear ? <option key={i} value={y}>{y}</option>:''
                    ))}
                </select>
            </td>
        </tr>
        <tr>
            <th  className='css-1ldd5ho' style={{opacity:maxYear>0?1 :0.5,color:site.kioskUiTextColor!==undefined && site.kioskUiTextColor!==null && site.kioskUiTextColor!=='' ? site.kioskUiTextColor : Colorsx}} >{language.Make}</th>
            <td>
                <select disabled={maxYear>0 ? false : true} className='css-17vg6hn' onChange={(e)=>{ setModel(''); setMake(e.target.value); getModels(e,'make')}}>
                        <option value={''}>{language.select} {language.Make}:</option>                    
                        {makes.map((m:any,i:any)=>(
                        <option key={i} value={m}>{m}</option>
                    ))}
                </select>
            </td>
        </tr>
        <tr>
            <th  className='css-1ldd5ho' style={{opacity:Make!=='' ? 1 :0.5,color:site.kioskUiTextColor!==undefined && site.kioskUiTextColor!==null && site.kioskUiTextColor!=='' ? site.kioskUiTextColor : Colorsx}} >{language.Model}::</th>
            <td >
                <select  disabled={Make!=='' ? false : true} className='css-17vg6hn' onChange={(e:any)=>setModel(e.target.value)}>
                    <option value={''}>{language.select} {language.Model}</option>
                    {Models.map((mo:any,i:any)=>(
                        <option key={i} value={mo}>{mo}</option>
                    ))}
                </select>
            </td>
        </tr>
      </tbody>
    </table>
    <br/><br/><br/>
    <div style={{display:'ruby'}} className="addvehiclebutton centerDivName">
      <button   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>data.cancleAddVehicle()} className='css-9jocrn nextbuttoncss' >{language.cancle}</button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button  disabled={addVehicledisable || Make==='' || minYear===0 || maxYear===0 || Model==''} style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>AddNewVehiclePick()} className=' css-1cbky20 nextbuttoncss' >{language.add}</button>
    </div>
        </div>
    )


}



export const makes= [
    "ACURA",
   //  "AMC",
    "AMERICAN MOTORS",
    "AUDI",
    "BMW",
    "BUICK",
    "CADILLAC",
    "CHEVROLET",
    "CHRYSLER",
    "DAEWOO",
    "DAIHATSU",
    "DATSUN",
    "DODGE",
    "EAGLE",
    "FIAT",
    "FORD",
    "GEO",
    "GMC",
    "HONDA",
    "HUMMER",
    "HYUNDAI",
    "INFINITI",
    "INTERNATIONAL",
    "ISUZU",
    "JAGUAR",
    "JEEP",
    "KIA",
    "LAND ROVER",
    "LEXUS",
    "LINCOLN",
    "MASERATI",
    "MAZDA",
    "MERCEDES-BENZ",
    "MERCURY",
    "MERKUR",
   //  "MG",
   //  "MGS GRAND SPORT (MARDIKIAN)",
    "MINI",
    "MITSUBISHI",
    "NISSAN",
    "OLDSMOBILE",
    "PLYMOUTH",
    "PONTIAC",
    "PORSCHE",
    "RAM",
   //  "RENAULT",
    "SAAB",
    "SATURN",
   //  "SCION",
    "SMART",
    "SUBARU",
    "SUZUKI",
    "TOYOTA",
    "TRIUMPH",
    "VOLKSWAGEN",
    "VOLVO",
   ];