import { Icon, Spinner } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Keypads } from '../components/keypads';
import { ScaleLoader } from 'react-spinners';
import { errorInOperation } from '../kiosklog/ServerLogging';
import { strings } from '../actions/string';
import { UserAction } from '../actions/urserAction';
import { ExternalCss } from '../components/ExternalCss';

export const Zip=()=>{
    const location = useLocation();
    const routes=location.state.site.routes;
    const [businesses,setBusinesses]=useState(null as any)
    const [site,setsite]=useState(null as any)
    const [restartCheckIn,setrestartCheckIn]=useState(false)
    const [zip,setZip]=useState('')
    const [zipIsValid,setzipIsValid]=useState(false)
    const [IsUpdating,setIsUpdating]=useState(location.state.IsUpdating)
    const navigate= useNavigate();
    const [continueOkay,setcontinueOkay]=useState(false)
    const [Nextdisable,setNextdisable]=useState(false)
    const language  =location.state.language==='en' ? strings.en : strings.esp;
    useEffect(()=>{
        setBusinesses(location.state.business)            
        setsite(location.state.site)
        if(routes.zip.enabled===false || ( location.state.user!==false && location.state.IsUpdating===false)){
          NextfinishButton()
      }
    })

const  keypadValidator  = (zip: string, value: string) => {
        let newZip;
        

        if (zip.length < 5 && value.match(/\d/g)) {
          newZip = zip + value;
    
          if (newZip.length === 5) {
            setzipIsValid(true);
          }
    
          return newZip;
        }
    
        if (value === "clear") {
            setzipIsValid(false);
    
          return "";
        }
    
        if (value === "backspace") {
          newZip = zip.slice(0, -1);    
          setzipIsValid(false);
    
          return newZip;
        }
    
   
        return zip;
      };
    
    
    const handleKeypadButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { value } = event.currentTarget;
        const zipx = keypadValidator(zip, value);
        setZip(zipx)
    
      };

      const NextfinishButton=async()=>{
        const site = location.state.site;
        const business = location.state.business;
        const user = location.state.user;
        const firstName= location.state.firstName;
        const lastName=location.state.lastName;
        const mobile =location.state.mobile;
        const lang=location.state.language;
        const optOut=location.state.optOut;
        const path = '/'+business.path+'/'+site.path
        if(location.state.IsUpdating){
          setNextdisable(true)
          await UserAction('updateUser',{userId:location.state.userId,zip
        })
          location.state.userId = location.state.userId;
          location.state.IsUpdating=false; 
          location.state.IsUpdated=true;      
          setNextdisable(false)
          navigate(path+'/thanks',location)
        }else{
        navigate(path+'/dateofbirth',{state:{mobile,user,business,site,firstName,lastName,zip,language:lang,optOut,IsUpdating:false}})
        }
    }
    
    const restartCheckInfun=async()=>{
      setrestartCheckIn(true)
      const  error = { 
             associated_data:JSON.stringify('restart checkIn from zip screen'),
             class_name:'vehicle',
             error_message:'restart checkIn',
             error_type:'component error',
             exception_message:JSON.stringify('restart checkIn'),
             file_name:'zip.tsx',
             function_name:'zip-Component',
             }
       const errorInOperationx = await errorInOperation(error);
       if(errorInOperationx){
       const path = '/'+businesses.path+'/'+site.path  
       navigate(path,{state:{business:location.state.business,site:location.state.site}})
         }
 }

    return( 
        businesses!==null && site!==null ?<div  className='main-container' style={{background:site.kioskUiBackgroundColor!==undefined && site.kioskUiBackgroundColor!==null &&   site.kioskUiBackgroundColor!=='' ? site.kioskUiBackgroundColor :  businesses.theme.background,height:'100vh'}}>
            <div className="">
            <ExternalCss/>
                <h1 className="enternameTitle ziptitle" style={{color: site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>{language.pleaseenterzip}</h1>
                <button  disabled={restartCheckIn} style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>restartCheckInfun()} className='optoutbtn restartCheckinButton' >{restartCheckIn ? <ScaleLoader  width={10} height={15} color="#fff" /> :language.restartCheckIn}</button>
            </div>  
                    <div className='centerDiv' style={{display:'grid'}} >
                        
         <div style={{display:'grid'}}>        
             <div className="css-1rarh3x">
                    <input type="tel" placeholder="xxxxx" readOnly={true} value={zip} className="css-5yk6n css-5yk6nspecial " />
                    <button disabled={continueOkay} value="backspace" className="css-1ceqh3h" onClick={(e:any)=>handleKeypadButton(e)} style={{background:site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>
                    <svg width="2rem" height="2rem" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>
                    </button>                
                </div>
            <Keypads disable={continueOkay} type="mobile" handleKeypadButton={(e:any)=>handleKeypadButton(e)} site={site} business={businesses}/>
            <br/>
      
         </div>

         
         </div>  
         <div style={{display:'flex'}} className="centerDivName">
            {routes.zip.optional===true  && !IsUpdating  ? <button   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{language.NoThanks}</button>

            :''}
                
                    <button  disabled={!zipIsValid || Nextdisable} style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{IsUpdating ? language.update :language.NextFinish}</button>
                    </div>   
        </div>:<p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>
    )
    
}