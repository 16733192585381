
import React, { useEffect, useState } from 'react';
import store from '../actions/store/store';
import { H1, Spinner } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
// import { Keypads } from '../components/keypads';
const Businesses=()=>{
const [businesses,setBusinesses]=useState([] as any)
const [bool,setBool]=useState(false)
const navigate= useNavigate();
    useEffect(()=>{
        setTimeout(() => {
      getBusineses()         
         }, 10);
    },[bool])

const getBusineses=()=>{
    if(businesses[0]===undefined ){
        setBusinesses(store.getState().BusinessesReducer.Businesses)  
        setBool(!bool)        
 }
}
    const openSites=(x:any)=>{
     navigate(x.path,{state:{x}})
    }

    return (
        businesses[0]!==undefined ?<div>
            { businesses.map((x:any,i:any)=>(
                <H1 key={i} className='p-10H-1' onClick={()=>openSites(x)}>{x.path}</H1>
            ))
            }
        </div>: <p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>
    )
}


export default Businesses