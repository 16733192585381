import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { UsersCollection, firebase } from "../global/firebase";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

export const UserAction=async(type:string,data:any)=>{

    const functions = getFunctions();
    const getUserLoyaltyCycle = httpsCallable(functions, 'getUserRewardFire');

const IsRecheckIn=async(data:any)=>{
    const d = new Date();
        d.setHours(0);    
    const checkIn =await getDocs(query(collection(firebase,'checkIns'),
    where('mobile','==',data.mobile),
    where('businessId','==',data.businessId),
    where('siteId','==',data.siteId),
    where('timestamp','>=',d),
    ))
    
    return checkIn.empty
}


const IsuserExistIn1=async(data:any)=>{  
    const checkIn =await getDocs(query(collection(firebase,'checkIns'),
    where('mobile','==',data.mobile),
    where('businessId','==',data.businessId),
    where('siteId','==',data.siteId),
    orderBy('timestamp','asc')
    ))
    
    return checkIn
}

switch (type) {
    case 'mobileContinue':        
        const newcosempty1 = await IsuserExistIn1(data);
        if(newcosempty1.empty){
            return false
        }else{
            try {
                console.log(newcosempty1.docs[0].data(),'user already exist!');
                
            const idx= newcosempty1.docs[0].data()['userId']
            const user =await getDoc(doc(UsersCollection,idx))
            // const user =await getDocs(query(UsersCollection,where('mobile','==',data.mobile)))
            if(user.exists()){
                console.log(user.data(),'userinfo');
                
            return user.data()
            }else{
                return false 
            }                 
              } catch (error) {
                console.log(error);
                return false 
                
           }
        }
       break;
   case 'addUser':
        const docid= await addDoc(collection(firebase, "users"), data);
        console.log(docid.id,'okay success report');
        return docid.id;
   case 'updateUser':
   
    await updateDoc(doc(firebase,'users',data.userId),data) 
    return true;    

   case 'uploadImages':
    const storage = getStorage();
    const metadata = {
    contentType: data.type
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, 'checkIns/'+data.businessId+'/' +data.checkinId+'/'+ data.file.name);
    const uploadTask = uploadBytesResumable(storageRef, data.file, metadata);
    console.log(uploadTask,'uihi');
    return uploadTask.then(async()=>{
        console.log('uploaded','uihi');
       return await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log(downloadURL,'uihi');
        
        return downloadURL;
        });
    });

    case 'addCheckIn':
     if(data.FinalCheckIn['userId']!=='opt-out'){
            const recheck=await IsRecheckIn(data.FinalCheckIn);
            const newcosempty =await  IsuserExistIn1(data.FinalCheckIn)
            try {
        if(!newcosempty.empty){  
            try {
                
            console.log(newcosempty.docs[0].data(),' marks');
            const docx =newcosempty.docs[0].data();
            if(docx.markForSellingCar!==undefined){
                data.FinalCheckIn.markForSellingCar = docx.markForSellingCar
            }
            if(docx.markForTheft!==undefined){
                data.FinalCheckIn.markForTheft = docx.markForTheft
            }
            if(docx.markUserNotes!==undefined){
                data.FinalCheckIn.markUserNotes = docx.markUserNotes
            }
            if(docx.militaryFirstResopnder!==undefined){
                data.FinalCheckIn.militaryFirstResopnder = docx.militaryFirstResopnder
            }
            if(docx.checkedIdCustomer!==undefined){
                data.FinalCheckIn.checkedIdCustomer = docx.checkedIdCustomer
            }
            if(docx.bannedCustomerOption!==undefined){
                data.FinalCheckIn.bannedCustomerOption = docx.bannedCustomerOption
            }
            


            } catch (error) {
                console.log(error,'error when adding checkin marks');
                
            }      
            if(!recheck){ data.FinalCheckIn.kind='recheck'}
        }
            } catch (error) {
                console.log(error);
                
            }
    }
        
        await setDoc(data.checkinsRef, data.FinalCheckIn);
        await fs13CheckInsOrganizedByDate(data.FinalCheckIn)

        return true;
   case 'getLoyaltyText':
   return await getUserLoyaltyCycle(data).then((res:any)=>{    
         return res.data;
    }).catch((e:any)=>{
        return e;
    })
    case 'updateUser':
       await updateDoc(doc(collection(firebase, "users"),data.userId), data.payload);
       console.log('okay success report on update User');
       return true;
    default:
        return null
        break;
}





}

const fs13CheckInsOrganizedByDate=async(checkIn:any)=>{

    const date =new Date();
    const month = date.getMonth()+1;
    const mm = month<10 ? '0'+month : month;
    const dd =  date.getDate()<10 ? '0'+date.getDate() : date.getDate();
    const yyyy = date.getFullYear();
    if(checkIn.userId==='opt-out'){
      checkIn.mobile = (checkIn.name.firstname+'_'+checkIn.name.lastname).replace(/\s+/g, '');
    }
    setDoc(doc(collection(firebase, "fs13CheckInsOrganizedByDate"),checkIn.businessId),{check:true})

    const fs17concat = checkIn.businessId+'_'+yyyy+'_'+mm+'_'+dd
    const fs13DocId = checkIn.siteId+'_'+checkIn.mobile+'_'+mm+'_'+yyyy+'_'+dd
    
    const lastpointQuery = doc(collection(firebase, `fs19LastCheckInPointers/${checkIn.businessId}/fs20LastCheckInPointerByKiosk`),checkIn.siteId)
    
    const fs13CheckInsOrganizedByDate = doc(collection(firebase, `fs13CheckInsOrganizedByDate/${checkIn.businessId}/fs14CheckinsByBusiness/${checkIn.siteId}/fs15CheckinsByYear/${checkIn.siteId+'_'+yyyy}/fs16CheckinsByMonth/${checkIn.siteId+'_'+yyyy+'_'+mm}/${'fs17_'+fs17concat}`),fs13DocId)
    const recheckQuery = collection(firebase, `fs18Recheckins`) 
   
    if(checkIn.kind!==undefined && checkIn.kind==='recheck' ){

  const qry =  await addDoc(recheckQuery,checkIn).then(async(vid:any)=>{
  const  bannedCustomerOption =checkIn!==undefined && checkIn!==null&& checkIn.bannedCustomerOption!==undefined &&  checkIn.bannedCustomerOption===true ? true : false
      return await updateDoc(fs13CheckInsOrganizedByDate,{'recheck_pointers':arrayUnion(vid.id),'bannedCustomerOption': bannedCustomerOption})
              .then(async(v:any)=>{
              // fs19 Last CheckIn Pointers 
            return   await setDoc(lastpointQuery,{'document_id':vid.id,'type':'recheckIn'}) 
              .then(async(vu:any)=>{
                  return true 
                 })
              .catch((e:any)=>{  console.log(e,'fs19LastCheckInPointers');  }); 
                    }).catch((e:any)=>{ 
                        return e  
                        });
      }).catch((e:any)=>{
          return e
  
          });   
    return qry;
    }else{

      const qry =  await setDoc(fs13CheckInsOrganizedByDate,checkIn).then(async(v:any)=>{
         return   await setDoc(lastpointQuery,{'document_id':fs13DocId,'type':'checkIn'})
        .then(async(vx:any)=>{ 
           return true; 
           })
        .catch((e:any)=>{  
          return e
          }); 

          }).catch((e:any)=>{ 
             return e  
            });
      return qry;

    }

    

  }

