import * as React from "react";


const Logo= ({
  border,
  borderColor,
  logo,
  maxHeight,   
  kioskUiLogoWidth,
  kioskUiLogoHeight,
}:any) => (
  <img
    src={logo}
    alt="logo"
    style={{ 
    width: kioskUiLogoWidth, height: kioskUiLogoHeight, border:border?"3px solid "+ (borderColor?borderColor: "#5cba0e"):"none" ,
    maxWidth:  kioskUiLogoWidth || "30vw",
    maxHeight: (kioskUiLogoHeight ? kioskUiLogoHeight : maxHeight || "20vh"),
    margin: '1vh',
    filter: 'drop-shadow(1px 2px black)',
    borderRadius: '12px',

    }}

  />
);

export default Logo;
