import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Businesses from './pages/Businesses';
import { connect } from 'react-redux';
import initApp from './actions/init';
import { Sites } from './pages/sites';
import { Mobile } from './pages/Mobile';
import { Name } from './pages/Name';
import { Dateofbirth } from './pages/Dateofbirth';
import { Zip } from './pages/zip';
import { Vehicle } from './pages/vehicles';
import { Signature } from './pages/signature';
import { Customertype } from './pages/Customertype';
import { Email } from './pages/email';
import { Supercard } from './pages/supercard';
import { Thanks } from './pages/thanks';
import { Optin } from './pages/Optin';
import { ExternalCss } from './components/ExternalCss';

function App(datar:any) {

  const [init,setinit]=useState(false);

  useEffect(()=>{
  if(datar.BusinessesReducer.fetching===false){
    setinit(true);  
} 


})

  return (
    <div className="App">  
            <ExternalCss/>   
       <BrowserRouter>
      <Routes>
      <Route path="/" element={<Businesses />}> </Route>
      <Route path="/:business" element={<Sites />}></Route>
      <Route path="/:business/:site" element={<Mobile />}></Route>
      <Route path="/:business/:site/name" element={<Name />}></Route>       
      <Route path="/:business/:site/dateofbirth" element={<Dateofbirth />}></Route>  
      <Route path="/:business/:site/zip" element={<Zip />}></Route>  
      <Route path="/:business/:site/vehicles" element={<Vehicle />}></Route> 
      <Route path="/:business/:site/signature" element={<Signature />}></Route> 
      <Route path="/:business/:site/customertype" element={<Customertype />}></Route> 
      <Route path="/:business/:site/email" element={<Email />}></Route> 
      <Route path="/:business/:site/supercard" element={<Supercard />}></Route>     
      <Route path="/:business/:site/thanks" element={<Thanks />}></Route>  
      <Route path="/:business/:site/optin" element={<Optin />}></Route> 

      </Routes>
    </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state:any) => ({
  ...state
});


export default connect(mapStateToProps,initApp())(App);
