
export const strings={
    en:{
      gopullitmsgwelcome:'Congratulations! You have a free check-in coupon!',
      gopullitmsgsecond:'Welcome Back! Thanks for Shopping at GO Pull-It!',
        selectDay:'Select day',
        pleaseenteryourname:'Please Enter Your Name',
        firstName:'First',
        lastName:'Last',
        restartCheckIn:'Restart Check-In',
        NoThanks:'No Thanks',
        NextFinish:'Next/Finish Check In',
        Next:'Next',
        pleaseenterzip:'Please Enter Your Zip',
        dob:'Date of Birth',
        Selectmonth:'Select month',
        month:'Month',
        day:'Day',
        year:'Year',
        selectyear:'Select year',
        agevalidation:'Must be 18 years or older to enter.',
        receiveNotice:'Receive Notices When These Vehicles Arrive in Inventory',
        addnewvehicle:'Add New Vehicle',
        titlevehicle:"It looks like you haven't added a vehicle yet.",
        titlesubvehicle:"Click 'Add New Vehicle' below to start receiving SMS notifications on your picks.",
        select:'Select',
        MinYear:'Min Year:',
        MaxYear:'Max Year:',
        Make:'Make',
        Model:'Model',
        cancle:'Cancel',
        add:'Add',
        changetoEnglish:'Change to English',
        iaccept:'I Accept',
        pleasesignbelow:'Please Sign Below',
        customertypetitle:'Which type of customer are you?',
        fixcar:"I'm fixing my car",
        machanic:"I'm a mechanic",
        bodyman:"I'm a body man",
        exports:"I'm an exporter",
        garage:"I own a garage",
        shop:"I own a body shop",
        carlot:'I own a car lot',
        customcar:"I build custom cars",
        email:'Please Enter Your Email',
        supercard:'Please Enter Your Supercard',
        complete:'Check In Complete!',
        finish:'Finish',
        updateProfile:'Tap one of the buttons below to update your profile',
        disclaimer:
        "By pressing Yes, you are signing up to receive automated text alerts from  an autodialer system. Consent to receive automated text alerts not required for purchase. Approx 9 msgs/mo. Msg &amp; Data Rates May Apply. Reply HELP for HELP, STOP to cancel.",
        optInHeader:
        "Would you like to receive text messages notifying you of upcoming sales, specials and discounts?",
        Yes:'Yes',
        No:'No',
        update:'Update',
        addName: "Add Name",
        addSignature: "Add Signature",
        addSupercard: "Add Supercard",
        addVehicle: "Add New Vehicle",
        addZip: "Add Zip",
        alreadyCheckedIn:
          "It looks like you already checked in today. Welcome back!",
        cancel: "Cancel",
        checkInCount1: "You've checked in ",
        checkInCount2: "times",
        closeContactWithCovidLast14Days:
          "Have you had close contact with or cared for someone diagnosed with COVID-19 within the last 14 days?",
        couponsHeader: "Coupons",
        covidDisclaimer:
          "In an effort to reduce the risk of COVID-19 exposure to employees, all visitors must complete the following screening questions:",
        covidEntryDenied:
          "Due to the CDC guidelines and for the safety of our team members and guests we are unable to grant you access to our facility at the moment. We appreciate your understanding and cooperation.",
        datOfBirthHeader: "Date of Birth",
        emailHeader: "Please Enter Your Email:",
        experiencedFluLikeSymptoms:
          "Have you experienced any cold or flu-like symptoms in the last 14 days (fever, cough, shortness of breath or other respiratory problem)?",
        firstname: "First",
        goBack: "Go Back",
        home: "Finish Check-in",
        homeReset: "Restart Check-In",
        language: "eng",
        languageHeader: "Please Choose Your Language:",
        lastname: "Last",
        make: "Make",
        maxYear: "Max Year",
        minAge:'Must be',
        minAge1:'years or older to enter.',
        minYear: "Min Year",
        model: "Model",
        name: "Name",
        nameHeader: "Please Enter Your Name:",
        next: "Next/Finish Check In",
        nextCouponGoal: "Next",
        noModels: "Sorry, No Models Found",
        noVehicle1: "It looks like you haven't added a vehicle yet.",
        noVehicle2:"Click 'Add New Vehicle' below to start receiving SMS notifications on your picks.",
        pleaseSign: "Please Sign Below",
        pleaseSign_liability:" Please sign the liability waiver and used tire waiver ",
        receiptCode: "Receipt",
        receiptCodeInstructions: "Please Scan the barcode on your receipt",
        rewardTextLan: "You are %% check-ins away from a %% coupon!",
        signature: "Signature",
        signatureAgreement:
          "By pressing below, I acknowledge I have read, understand, and will comply with the above.",
        signatureAgreementButton: "I Accept",
        signatureAgreementliabilitywaiver:'By pressing below, I acknowledge I have read, understand, and will comply with the above general waiver and used tire waiver',
        signatureAgreementliabilitywaiverButton: "I Accept",
        supercardHeader: "Please enter your Supercard Number:",
        tapBelow: "Tap one of the buttons below to update your profile",
        thanksCheckin: "Thanks for checking in!",
        thanksHeader: "Check In Complete!",
        thanksHeaderVehicleTap: "Thanks for adding your vehicles!",
        traveledLast14Days:
          "Have you traveled outside the country or been in close contact with anyone who has traveled outside the country within the last 14 days?",
        typeBodyMan: "I'm a body man",
        typeBuildCustomCars: "I build custom cars",
        typeExporter: "I'm an exporter",
        typeFixingCar: "I'm fixing my car",
        typeHeader: "Which type of customer are you?",
        typeMechanic: "I'm a mechanic",
        typeOwnBodyShop: "I own a body shop",
        typeOwnCarLot: "I own a car lot",
        typeOwnGarage: "I own a garage",
        vehicles: "Add Vehicle Picks",
        vehiclesHeader: "Receive Notices When These Vehicles Arrive in Inventory",
        wapaustincheckin: "You're Done! Please Move Forward. Thank you!",
        wapfacebookcheckin:
          "Check in on Facebook and get a free entry, just show cashier!",
        welcomeBack: "Welcome back! Please sign below",
        welcomeBackThanks: "Welcome back",
        welcomeBackThanksIf: "Welcome",
        welcomeThanks: "Welcome",
        welcomeThanksVehicleTap: "We will text you when your vehicle(s) arrive.",
        yes: "Yes",
        zip: "Zip",
        zipHeader: "Please Enter Your Zip:",
    },
    esp:{
      gopullitmsgwelcome:'Congratulations! You have a free check-in coupon!',
      gopullitmsgsecond:'Welcome Back! Thanks for Shopping at GO Pull-It!',
       selectDay:'seleccione el día',
        pleaseenteryourname:'Por favor, escriba su nombre',
        firstName:'Nombre de pila',
        lastName:'Apellido',
        restartCheckIn:'Reiniciar el registro',
        NoThanks:'No, gracias',
        NextFinish:'Siguiente/Finalizar',
        Next:'Siguiente',
        pleaseenterzip:'Por favor ingrese su código postal',
        dob:'Fecha de nacimiento',
        Selectmonth:'Seleccione mes',
        month:'mes',
        day:'día',
        year:'año',
        selectyear:'seleccionar año',
        agevalidation:'Debe tener 18 años o más para ingresar.',
        receiveNotice:'Reciba avisos cuando estos vehículos lleguen al inventario',
        addnewvehicle:'Agregar nuevo vehículo',
        titlevehicle:"Parece que aún no has agregado un vehículo.",
        titlesubvehicle:"Haga clic en 'Agregar vehículo nuevo' a continuación para comenzar a recibir notificaciones por SMS sobre sus selecciones.",
        select:'seleccionar',
        MinYear:'Año mínimo:',
        MaxYear:'Año máximo:',
        Make:'Hacer',
        Model:'Modelo',
        cancle:'cancelar',
        add:'agregar',
        changetoEnglish:'Cambiar a Inglés',
        iaccept:'Acepto',
        pleasesignbelow:'Por favor firme a continuación',
        customertypetitle:'¿Qué tipo de cliente eres?',
        fixcar:"estoy arreglando mi auto",
        machanic:"Soy mecanica",
        bodyman:"soy un hombre de cuerpo",
        exports:"Soy exportadora",
        garage:"tengo un garaje",
        shop:"soy dueño de un taller de carrocería",
        carlot:'tengo un lote de autos',
        customcar:"construyo autos personalizados",
        email:'Por favor introduzca su correo electrónico',
        supercard:'Por favor ingrese su supertarjeta',
        complete:'¡Registro completo!',
        finish:'finalizar',
        updateProfile:'Toque uno de los botones a continuación para actualizar su perfil',
        disclaimer:
        " Presionando si, te estás inscribiendo para recibir alertas automáticas de texto de un Sistema de marcado automático. El consentimiento para recibir alertas automáticas de texto no es necesario para compra. Aproximadamente 9 mensajes / mes. Se pueden aplicar tarifas de mensajes y datos. Responde a la ayuda por ayuda, deja de cancelar. ",
        optInHeader:
        "¿Le gustaría recibir mensajes de texto que le informen sobre próximas ventas, ofertas especiales y descuentos?",
        Yes:'Yes',
        No:'No',
        update:'Actualizar',
        addName: "Agregar Nombre",
        addSignature: "Agregar Firma",
        addSupercard: "Agregar Supercard",
        addVehicle: "Agregar Vehículo",
        addZip: "Agregar Zip",
        alreadyCheckedIn: "Parece que ya te registraste. ¡Vuelve pronto!",
        cancel: "Cancelar",
        checkInCount1: "Has marcado ",
        checkInCount2: "veces",
        closeContactWithCovidLast14Days:
          "¿Ha tenido contacto cercano o cuidado a alguien diagnosticado con COVID-19 en los últimos 14 días?",
        couponsHeader: "Cupones",
        covidDisclaimer:
          "En un esfuerzo por reducir el riesgo de exposición de COVID-19 a los empleados, todos los visitantes deben completar las siguientes preguntas de detección:",
        covidEntryDenied:
          "Debido a las pautas de los CDC y por la seguridad de los miembros de nuestro equipo e invitados, no podemos otorgarle acceso a nuestras instalaciones en este momento. Agradecemos su comprensión y cooperación",
        datOfBirthHeader: "Fecha de Nacimiento",
        emailHeader: "Por Favor, Escriba Su Email:",
        experiencedFluLikeSymptoms:
          "¿Ha experimentado algún síntoma de resfriado o gripe en los últimos 14 días (fiebre, tos, falta de aire u otro problema respiratorio)?",
        firstname: "Nombre",
        goBack: "Atrás",
        home: "Finalizar el check-in",
        homeReset: "Reanudar registrarse",
        language: "esp",
        languageHeader: "Por favor elija su idioma:",
        lastname: "Appelido",
        make: "Marca",
        maxYear: "Año Max",
        minAge:'Debe tener ',
        minAge1:'años o más para ingresar.',
        minYear: "Año Min",
        model: "Modelo",
        name: "Nombre",
        nameHeader: "Por Favor, Escriba Su Nombre:",
        next: "Siguiente",
        nextCouponGoal: "Siguiente",
        noModels: "Lo sentimos, no se encontraron modelos",
        noVehicle1: "Parece que aún no has agregado un vehículo.",
        noVehicle2:"Haga clic en 'Agregar vehículo nuevo' a continuación para comenzar a recibir notificaciones por SMS sobre sus selecciones.",
        pleaseSign: "Por Favor Firme Abajo",
        pleaseSign_liability:"Firme la exención de responsabilidad y la exención de neumáticos usados",
        receiptCode: "Recibo",
        receiptCodeInstructions:
          "Por favor escanee el código de barras en su recibo",
        rewardTextLan: "Estás a %%  registros de distancia de %% en el cupón!",
        signature: "Firma",
        signatureAgreement:
          "Al presionar a continuación, reconozco que he leído, entiendo y cumpliré con lo anterior.",
        signatureAgreementButton: "Acepto",
        signatureAgreementliabilitywaiver:'Al presionar a continuación, reconozco que he leído, entiendo y cumpliré con la exención general anterior y la exención de llantas usadas',
        signatureAgreementliabilitywaiverButton: "Acepto",
        supercardHeader: "Ingrese su número de Supercard:",
        tapBelow:
          "Toca uno de los botones a continuación para actualizar tu perfil",
        thanksCheckin: "¡Gracias por registrarte!",
        thanksHeader: "Registro completo!",
        thanksHeaderVehicleTap: "¡Gracias por agregar sus vehículos!",
        traveledLast14Days:
          "¿Ha viajado fuera del país o ha estado en contacto cercano con alguien que haya viajado fuera del país en los últimos 14 días?",
        typeBodyMan: "Soy un hombre de cuerpo",
        typeBuildCustomCars: "Yo construimos autos personalizados",
        typeExporter: "Soy exportador",
        typeFixingCar: "Estoy arreglando mi auto",
        typeHeader: "¿Qué tipo de cliente eres?",
        typeMechanic: "Soy mecanico",
        typeOwnBodyShop: "Yo dueños de un taller de carrocería",
        typeOwnCarLot: "Yo poseemos un lote de autos",
        typeOwnGarage: "Yo poseemos un garaje",
        vehicles: "Vehículos",
        vehiclesHeader:
          "Recibir Avisos Cuando Estos Vehículos Lleguen al Inventario",
        wapaustincheckin: "¡Ya terminaste! Por favor, avance. ¡Gracias!",
        wapfacebookcheckin:
          "Registrese en Facebook para entrar GRATIS y muestre al cajero.",
        welcomeBack: "¡Gracias por regresar! Por Favor Firme Abajo",
        welcomeBackThanks: "Bienvenidos de nuevo",
        welcomeBackThanksIf: "Bienvenidas",
        welcomeThanks: "Bienvenidos",
        welcomeThanksVehicleTap: "Le enviaremos un mensaje de texto cuando lleguen sus vehículos.",
        yes: "Si",
        zip: "Código Postal",
        zipHeader: "Por Favor Proporcione Su Código Postal:",

    }
}