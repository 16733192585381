
import { Button, Icon, Spinner } from '@blueprintjs/core';
import React, { useState } from 'react';
import '../assets/Finalstyle.css'


export const Keypads=(data:any)=>{

  if(data.business!==null && data.business.theme!==undefined && data.business.theme.colors!==undefined){
if(data.business.theme.colors.primary!==undefined && data.business.theme.colors.primary!=='' && (data.site.kioskUiButtonPrimaryColor===undefined || data.site.kioskUiButtonPrimaryColor===null || data.site.kioskUiButtonPrimaryColor==='')){
  data.site.kioskUiButtonPrimaryColor = data.business.theme.colors.primary
}
  }
  const [upper,setupper]=useState(false)
  const locationcheck = window.location.href.includes('wilberts');
 const keys  ={
        email: [
          ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "backspace"],
          ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
          ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
          ["#", "z", "x", "c", "v", "b", "n", "m", "&"],
          ["!", "'", "+", "-", "_", ".", "@", ".com"],
        ],
        letters: [
          ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "backspace"],
          ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
          ["shift", "z", "x", "c", "v", "b", "n", "m"],
          ["previous", " ", "next"],
        ],
        numbers: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "clear", "0"],
      };

      const height = "8vmin";
      const width =locationcheck? '9vmin' : "8vmin";

      
    const DotComButton = () => (
      <button
      className='keypadcss'
       style={{background:data.site.kioskUiTextColor ? data.site.kioskUiTextColor : 'rgb(252, 173, 20)',height:height, width: '12rem' }}
        disabled={data.disabled}        
        onClick={(ex:any)=>data.handleKeypadButton(ex)}
        value={".com"}
      >.com
      </button>
    );

    const BackspaceButton = () => (
      <Button
        style={{background:data.site.kioskUiButtonErrorColor ? data.site.kioskUiButtonErrorColor : 'red',height:height, width: width }}
        className='backbutton keypadcss'
        disabled={data.disabled}
        onClick={()=>data.onHandleKeypadClick()}
        value="backspace"
      >
   <svg width="2rem" height="2rem" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>

       </Button>
    );
    const NextButton = () => (
      
      <Button
        style={{background:data.site.kioskUiButtonSuccessColor ? data.site.kioskUiButtonSuccessColor : data.site.kioskUiButtonSuccessColor ,height:height, width: '12rem',color:'#fff' }}
        className={locationcheck ? 'css-1nsehko keypadcsfo' : 'keypadcss keypadcsfo'}
        disabled={data.disabledArrow ||  data.focusLastInputVar}        
        onClick={()=>data.focusLastInput(true)}
        value="next"
      > ⇨ </Button>
    );

    const PreviousButton = () => (
      <Button
        style={{background:data.site.kioskUiButtonSuccessColor ? data.site.kioskUiButtonSuccessColor : data.site.kioskUiButtonSuccessColor  ,height:height, width: '12rem' ,color:'#fff'}}
        className={locationcheck ? 'css-1nsehko keypadcsfo' : 'keypadcss keypadcsfo '}
        disabled={data.disabledArrow || data.focusFirstInputVar}        
        onClick={()=>data.focusFirstInput(true)}
        value="previous"
      > ⇦ </Button>
    );

    const ShiftButton = () => (
      <Button
        style={{color: data.capital===false && !upper ?   '#fff':'#000', background: data.capital===false && !upper ? (data.site.kioskUiButtonPrimaryColor ? data.site.kioskUiButtonPrimaryColor :  'rgb(43, 60, 139)'):'',height:height, width: width }}
        className='keypadcss keypadcsfo'
        disabled={data.disabled}       
   onClick={()=>setupper(!upper)}
        value="shift"
      >
        ⇧
      </Button>
    );


   

return (
  data.type==='mobile' ?
    <div className='mobilegrid'>
        {keys.numbers.map((value:any,index:any)=>(
             <Button 
             className={value === "clear" && locationcheck ? 'css-xtnboy' : 'keypadcss keypadcssnum'}
             style={{ color:data.site.kioskUiButtonKeyColor!==undefined && data.site.kioskUiButtonKeyColor!==null && data.site.kioskUiButtonKeyColor!=='' ? data.site.kioskUiButtonKeyColor :"#fff",
            background:value==='clear' ? data.site.kioskUiButtonErrorColor!==undefined && data.site.kioskUiButtonErrorColor!==null && data.site.kioskUiButtonErrorColor!=='' ?  data.site.kioskUiButtonErrorColor : 'rgb(226, 50, 41)'  : data.site.kioskUiButtonPrimaryColor!==undefined && data.site.kioskUiButtonPrimaryColor!==null && data.site.kioskUiButtonPrimaryColor!=='' ? data.site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)',
            // fontSize:`5vmin ${"!important"}`
          }}            
           
             disabled={data.disable}
             key={index}
             large={true}
             onClick={(e:any)=>data.handleKeypadButton(e)}
             value={value}
           >
             {value === "clear" ? "✖" : value}
           </Button>
        ))}
    </div>
    : data.type==='name' ?    
    <div style={{display:'block'}}>
      {keys.letters.map((row, rowIndex) => (
        <div key={rowIndex} style={{display:'flex',justifyContent:'center'}}>
          {row.map((value, keyIndex) => {
            switch (value) {
              case ".com":
                return <DotComButton key={keyIndex} />;
              case "backspace":
                return <BackspaceButton key={keyIndex} />;
              case "next":
                return <NextButton key={keyIndex} />;
              case "previous":
                return <PreviousButton key={keyIndex} />;
              case "shift":
                return <ShiftButton key={keyIndex} />;
              default:
                return (
                  <button
                  style={{ color:data.site.kioskUiButtonKeyColor!==undefined && data.site.kioskUiButtonKeyColor!==null && data.site.kioskUiButtonKeyColor!=='' ? data.site.kioskUiButtonKeyColor :"#fff",
                    background:value==='backspace' ? data.site.kioskUiButtonErrorColor!==undefined && data.site.kioskUiButtonErrorColor!==null && data.site.kioskUiButtonErrorColor!=='' ?  data.site.kioskUiButtonErrorColor : '#c70000'  : data.site.kioskUiButtonPrimaryColor!==undefined && data.site.kioskUiButtonPrimaryColor!==null && data.site.kioskUiButtonPrimaryColor!=='' ? data.site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)',width:value === " " ? "36vmin" :width}}
                     className='keypadcss'
                     disabled={data.disable}
                     key={keyIndex}
                     onClick={(e:any)=>data.handleKeypadButton(e)}
                     value=  {upper || data.capital ? value.toUpperCase(): value.toLowerCase()}                    
                  >
                   {upper || data.capital ? value.toUpperCase(): value.toLowerCase()}
                  </button>
                );
            }
          })}
        </div>
      ))}
    </div>
    : data.type==='email' ?    
    <div style={{display:'block'}}>
      {keys.email.map((row, rowIndex) => (
        <div key={rowIndex} style={{display:'flex',justifyContent:'center'}}>
          {row.map((value, keyIndex) => {
            switch (value) {
              case ".com":
                return <DotComButton key={keyIndex} />;
              case "backspace":
                return <BackspaceButton key={keyIndex} />;
              case "next":
                return <NextButton key={keyIndex} />;
              case "previous":
                return <PreviousButton key={keyIndex} />;
              case "shift":
                return <ShiftButton key={keyIndex} />;
              default:
                return (
                  <button
                  style={{  color:data.site.kioskUiButtonKeyColor!==undefined && data.site.kioskUiButtonKeyColor!==null && data.site.kioskUiButtonKeyColor!=='' ? data.site.kioskUiButtonKeyColor :"#fff",
                    background:value==='backspace' ? data.site.kioskUiButtonErrorColor!==undefined && data.site.kioskUiButtonErrorColor!==null && data.site.kioskUiButtonErrorColor!=='' ?  data.site.kioskUiButtonErrorColor : '#c70000'  : data.site.kioskUiButtonPrimaryColor!==undefined && data.site.kioskUiButtonPrimaryColor!==null && data.site.kioskUiButtonPrimaryColor!=='' ? data.site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)',width:value === " " ? "36vmin" :width}}
                    className='keypadcss'
                     disabled={data.disable}
                     key={keyIndex}
                     onClick={(e:any)=>data.handleKeypadButton(e)}
                     value={upper || data.capital ? value.toUpperCase(): value.toLowerCase()}                     
                  >
                    {upper || data.capital ? value.toUpperCase(): value.toLowerCase()}
                  </button>
                );
            }
          })}
        </div>
      ))}
    </div>
    :<p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>

)
}
