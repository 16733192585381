import styled from 'styled-components';

interface IButtonProps {
  bg?: string;
  disableColor?: string;
  color?: string;
  borderRadius?: "left" | "right";
  height?: string;
  margin?: string;
  size?: string;
  width?: string;
}

const Button:any = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props: IButtonProps) => props.size || "4.5vmin"};
  text-shadow: 1px 1px 1px black;
  background: ${(props: IButtonProps) => props.bg};
  border: 2px solid black;
  border-radius: ${(props: IButtonProps) =>
    props.borderRadius === "right" ? "0 12px 12px 0" : props.borderRadius === "left" ? "12px 0 0 12px" : "12px"};
  outline: none;
  box-shadow: 1px 2px 1px 1px black;
  width: ${(props: IButtonProps) => props.width};
  height: ${(props: IButtonProps) => props.height};
  margin: ${(props: IButtonProps) => props.margin || ""};
  padding: 0.25em 0.5em;
  color: ${(props: IButtonProps) => props.color || "white"};
  transform: translate(0, 0);
  &:disabled {
    cursor: not-allowed;
    background: ${(props: IButtonProps) => props.disableColor || "#888"};
  }
  &:active {
    box-shadow: 0 0 1px 1px #888;
    transform: translate(1px, 2px);
  }
`;



Button.displayName = "Button";
export default Button;
