

import {addbusiness, fetchingBool}  from '../actions/businesses/PushBusinessesReducer';
import { BusinessesCollection, firebase } from '../global/firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import store from './store/store';


 const initApp=async()=>{    

try {

    const q = query(BusinessesCollection);
    const doc =await getDocs(q)
    const b:any =[]
    if(!doc.empty){
    doc.forEach(async(r)=>{
     const data:any= r.data()   
     const qs = query(collection(firebase, 'businesses'+'/'+r.id+'/sites'));
     const docs =await getDocs(qs)
     if(!docs.empty){
      const arrSite:any =[]  
      docs.forEach((site)=>{
        const sitedata= site.data()
        sitedata.siteId =site.id
        arrSite.push(sitedata)        
      })
      data.sites=arrSite
    }
     b.push(data);
  
    });
console.log(doc.size,b.length);

 if(doc){
  setTimeout(() => {

   store.dispatch(addbusiness(b))
       
  }, 3000);
}

    }
    store.dispatch(fetchingBool(false))
    
} catch (error) {
    window.location.href=''
}


  }


export default initApp    