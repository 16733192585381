const BUSINESSES = 'BUSINESSES';
const FETCHING = 'FETCHING';

export function addbusiness(allbusinesses:any) {
  return {
    type: BUSINESSES,
    allbusinesses,
  }
}

export function fetchingBool(fetch:any) {
  return {
    type: FETCHING,
    fetch,
  }
}

const defaultbusiness ={ 
     Businesses: [],
     fetching:true
    }

function BusinessesReducer(state=defaultbusiness, action:any) {    
  switch (action.type) {
    case BUSINESSES:
      return {
          Businesses: action.allbusinesses,     
          fetching:state.fetching     
      };
    case FETCHING:
        return {
            Businesses: state.Businesses, 
            fetching:action.fetch         
        };
    default:
      return state;
  }
}


  export default BusinessesReducer