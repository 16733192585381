import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { firebase } from "../global/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const ff1LogKioskOperationStarted = httpsCallable(functions, 'ff1LogKioskOperationStarted');
const ff3LogKioskError = httpsCallable(functions, 'ff3LogKioskError');
const ff2LogKioskOperationFinished = httpsCallable(functions, 'ff2LogKioskOperationFinished');


let G_opration:any = '';
let G_businessId:any = '';
let G_siteId:any ='';
let G_OperationStartedCalled: boolean = false;
let G_OperationFinishedCalled: boolean = false;
let error=false;


const dateGenerate = ()=>{
  const timestamp = new Date();
  try{
  let dd:any;
  let mm:any;
  const yy =   timestamp.getFullYear();
   mm =   timestamp.getMonth()+1;
   dd =   timestamp.getDate();
  const hh =   timestamp.getHours();
  const m =   timestamp.getMinutes();
  const ss =   timestamp.getSeconds();
  const ms =   timestamp.getMilliseconds();

  if(mm<10){
    mm = '0'+mm;
  }
  if(dd<10){
    dd = '0'+dd;
  }

  const dateStr = `${yy}-${String(mm).padStart(2, '0')}-${String(dd).padStart(2, '0')}T${String(hh).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(ss).padStart(2, '0')}.${String(ms).padStart(3, '0')}Z`;
  console.log(dateStr);
  
  return new Date(Date.parse(dateStr));
}catch (error){

console.log(error,timestamp);
return new Date()
}

}

export const startOperation = async (business_id:any,site_id:any) => {
    // enforcing function to call only first time.


    
    if(G_OperationStartedCalled)
    {  
      return; 
    }
    G_OperationStartedCalled = true;
    G_OperationFinishedCalled=false;
    error=false;
    console.log('before date generate');
    const  start_timestamp=dateGenerate();
    console.log('after date generate',start_timestamp);
    
    const store = await addDoc(collection(firebase, "fs1KioskStartedOperations"), {
              businessId: business_id,
              siteId: site_id,
              start_timestamp
                   }).then((v:any)=>{
                        G_opration=v.id;
                        G_businessId = business_id;
                        G_siteId = site_id;
                        return true;
                  }).catch((e:any)=>{
                        return false;
                  });

return store;   

  }

  export const errorInOperation = async (errorLog: any )=>{

    errorLog.operation_id = G_opration;
    errorLog.business_id = G_businessId;
    errorLog.site_id = G_siteId;
    errorLog.timestamp = dateGenerate().toString();
    G_OperationStartedCalled=false
    error=true;
    return ff3LogKioskError(errorLog).catch((e:any)=>{ console.log(e,'errorr');  });


  }

  export const finishOperation =  async ()=>{
    console.log(error,G_OperationFinishedCalled,'pl');
    
    if(error===false && G_OperationFinishedCalled === false){
      G_OperationStartedCalled=false
      G_OperationFinishedCalled = true;
      const endtimestamp =dateGenerate().toString();
      
      // const data = {operation_id:G_opration,end_timestamp :endtimestamp};
         
try {
  await deleteDoc(doc(firebase,'fs1KioskStartedOperations',G_opration));
} catch (error) {
  
           console.log(error);
           
}
      // const return_ =  await  ff2LogKioskOperationFinished(data).then((re:any)=>{           
      //     return re.data
      //     }).catch((e:any)=>{ 
      //       const  error = {
      //         associated_data:e.toString(),
      //         class_name:'',
      //         error_message:'finishOperation error line no 55',
      //         error_type:e.code!==undefined ? e.code : '',
      //         exception_message:e.name!==undefined ? e.name : '',
      //         file_name:'ServerLogging.ts',
      //         function_name:'finishOperation',
      //         }
      //         errorInOperation(error);
      //       return true;
      //     });
      
      return true;
    }else{
      G_OperationStartedCalled=false
      return true;
    }
  }



