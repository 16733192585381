import { Icon, Spinner } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Keypads } from '../components/keypads';
import { ScaleLoader } from 'react-spinners';
import { errorInOperation } from '../kiosklog/ServerLogging';
import { strings } from '../actions/string';
import { UserAction } from '../actions/urserAction';
import { ExternalCss } from '../components/ExternalCss';

export const Supercard=()=>{
    const location = useLocation();
    const routes=location.state.site.routes;
    const [businesses,setBusinesses]=useState(null as any)
    const [site,setsite]=useState(null as any)
    const [restartCheckIn,setrestartCheckIn]=useState(false)
    const [IsUpdating,setIsUpdating]=useState(location.state.IsUpdating)
    const [Supercard,setSupercard]=useState('')
    const [SupercardIsValid,setSupercardIsValid]=useState(false)
    const [Nextdisable,setNextdisable]=useState(false)
    const navigate= useNavigate();
    const language  =location.state.language==='en' ? strings.en : strings.esp;
    const [continueOkay,setcontinueOkay]=useState(false)
    useEffect(()=>{
        setBusinesses(location.state.business)            
        setsite(location.state.site)

         if((routes.supercard==undefined || routes.supercard.enabled===false) || ( location.state.user!==false && location.state.IsUpdating===false)){
          NextfinishButton()
      } 
    })

const  keypadValidator  = (Supercard: string, value: string) => {
        let newSupercard;
        

        if (Supercard.length < 5 && value.match(/\d/g)) {
          newSupercard = Supercard + value;
    
          if (newSupercard.length === 5) {
            setSupercardIsValid(true);
          }
    
          return newSupercard;
        }
    
        if (value === "clear") {
            setSupercardIsValid(false);
    
          return "";
        }
    
        if (value === "backspace") {
          newSupercard = Supercard.slice(0, -1);    
          setSupercardIsValid(false);
    
          return newSupercard;
        }
    
   
        return Supercard;
      };
    
    
    const handleKeypadButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { value } = event.currentTarget;
        const Supercardx = keypadValidator(Supercard, value);
        setSupercard(Supercardx)
    
      };

      const NextfinishButton=async()=>{
        const site = location.state.site;
        const business = location.state.business;
        const user = location.state.user;
        const firstName= location.state.firstName;
        const lastName=location.state.lastName;
        const mobile =location.state.mobile;
        const zip=location.state.zip;
        const dateofbirth= location.state.dateofbirth;
        const vehicles=location.state.vehicles;
        const mugshot=location.state.mugshot;
        const signature=location.state.signature;
        const customertype=location.state.customertype;
        const email=location.state.email;
        const lang=location.state.language;
        const optOut=location.state.optOut;
        const path = '/'+business.path+'/'+site.path    
        if(location.state.IsUpdating){
          setNextdisable(true)
          await UserAction('updateUser',{userId:location.state.userId,supercard:Supercard
        })
          location.state.userId = location.state.userId;
          location.state.IsUpdating=false;  
          location.state.IsUpdated=true; 
          setNextdisable(false)    
          navigate(path+'/thanks',location)
        }else{        
        navigate(path+'/optin',{state:{mobile,user,business,site,firstName,lastName,zip,dateofbirth,vehicles,mugshot,signature,customertype,email,Supercard,language:lang,optOut,IsUpdating:false}})   
       }
      }

    const restartCheckInfun=async()=>{
        setrestartCheckIn(true)
        const  error = { 
               associated_data:JSON.stringify('restart checkIn from supercards screen'),
               class_name:'supercards',
               error_message:'restart checkIn',
               error_type:'component error',
               exception_message:JSON.stringify('restart checkIn'),
               file_name:'supercards.tsx',
               function_name:'supercards-Component',
               }
         const errorInOperationx = await errorInOperation(error);
         if(errorInOperationx){
         const path = '/'+businesses.path+'/'+site.path  
         navigate(path,{state:{business:location.state.business,site:location.state.site}})
           }
    }
      
    return(
        businesses!==null && site!==null ?<div  className='main-container' style={{background:site.kioskUiBackgroundColor!==undefined &&  site.kioskUiBackgroundColor!==null &&  site.kioskUiBackgroundColor!=='' ? site.kioskUiBackgroundColor :  businesses.theme.background,height:'100vh'}}>
            <div className="">
            <ExternalCss/>
                <h1 className="enternameTitle" style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>{language.supercard}</h1>
                <button  disabled={restartCheckIn} style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>restartCheckInfun()} className='optoutbtn restartCheckinButton' >{restartCheckIn ? <ScaleLoader  width={10} height={15} color="#fff" /> :language.restartCheckIn}</button>
            </div>  
                    <div className='centerDiv'>
                        
         <div style={{display:'grid'}}>        
             <div className="css-1rarh3x">
                    <input type="tel" placeholder="xxxxx" readOnly={true} value={Supercard} className="css-5yk6n" />
                    <button disabled={continueOkay} value="backspace" className="css-1ceqh3h" onClick={(e:any)=>handleKeypadButton(e)} style={{background:site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>
                        <Icon icon='arrow-left' size={20}/>
                    </button>                
                </div>
            <Keypads disable={continueOkay} type="mobile" handleKeypadButton={(e:any)=>handleKeypadButton(e)} site={site} business={businesses}/>
            <br/>
            <div style={{display:'flex'}} className="centerDivName">
            {routes.name.optional===true  && !IsUpdating ?   <button   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{language.NoThanks}</button>:''}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button  disabled={!SupercardIsValid || Nextdisable} style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{IsUpdating ? language.update :language.NextFinish}</button>
                    </div>
         </div>
         </div>     
        </div>:<p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>
    )
    
}