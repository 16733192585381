import { Icon, Spinner } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { differenceInYears } from "date-fns";
import { errorInOperation } from '../kiosklog/ServerLogging';
import { strings } from '../actions/string';
import { UserAction } from '../actions/urserAction';
import { ExternalCss } from '../components/ExternalCss';
export const Dateofbirth=()=>{

    const location = useLocation();
    const routes=location.state.site.routes;
    const [businesses,setBusinesses]=useState(null as any)
    const [site,setsite]=useState(null as any)
    const [restartCheckIn,setrestartCheckIn]=useState(false)
    const [IsUpdating,setIsUpdating]=useState(location.state.IsUpdating)
    const navigate= useNavigate();
    const [month,setmonth]=useState('null' as any);
    const [year,setyear]=useState('' as any);
    const [days,setdays]=useState([] as any)
    const [day,setday]=useState('null' as any)
    const [dateOfBirthIsValid,setdateOfBirthIsValid]=useState(false);
    const [dateOfBirth,setdateOfBirth]=useState('' as any)
    const [Nextdisable,setNextdisable]=useState(false)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthNamessp = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const language  =location.state.language==='en' ? strings.en : strings.esp;
    useEffect(()=>{
        setBusinesses(location.state.business)            
        setsite(location.state.site)
        if(routes.dateofbirth.enabled===false || ( location.state.user!==false && location.state.IsUpdating===false)){
          NextfinishButton()
      }
    })

    const getYears = (businessId:string) => {
        const today = new Date();
        const maxYear = today.getFullYear();
        const minYear = maxYear - 93;
      
        let minAge = 18;
        if (businessId === "3DmMhzu7mCa3hpLUEbp9") {
          // U Pull U Save
          minAge = 16;
        }
        const yearDefault = maxYear - minAge;
        const years = [];
      
        for (let year = minYear; year <= maxYear; year++) {
          years.push(year);
        }
        if(year==''){
        setyear(yearDefault)
        }
        return { yearDefault, years };
      };
      
      const getDays = (monthString: string, yearString: string) => {
        const month = parseInt(monthString, 10);
        const year = parseInt(yearString, 10);
        const lastDay = new Date(year, month, 0).getDate();
        const days = [];      
        for (let day = 1; day <= lastDay; day++) {
          days.push(day);
        }
        // days.sort((a,b)=>b-a);
        return days;
      };

      const  generateDays = (month:any,year:any) => {
        if (month && year) {           
          const days = getDays(month, year).map((day, index) => (
            <option key={index} value={day}>
              {day}
            </option>
          ));
          setdays(days);
        }
      };

    const validateDateOfBirth = ({ day, month, year }: { day: string; month: string; year: string }) => {
    if (!day || !month || day === "null" || month === "null") {
        return false;
    }
    console.log(month,day,year);
    
    const dateOfBirth = new Date(`${month}/${day}/${year}`);
    const today = new Date();
    const difference = differenceInYears(today, dateOfBirth);
    let minAge = 18;
    const businessId = location.state.business.businessId;
    if (businessId === "3DmMhzu7mCa3hpLUEbp9") {
      // u pull u save
      minAge = 16;
    }

    if (difference >= minAge) {
      return true;
    } else {
      return false;
    }
    };


    const handleYear = ({ currentTarget: { value: year } }: React.FormEvent<HTMLSelectElement>) =>{
        setyear(year);
        processDateOfBirth(year,month);
    }

    const handleMonth = ({ currentTarget: { value: month } }: React.FormEvent<HTMLSelectElement>) =>{
        setmonth(month);
        processDateOfBirth(year,month);
    }

    const  processDateOfBirth = (year:any,month:any) => {    
        generateDays(month,year);          
         
        const dateOfBirthIsValid = validateDateOfBirth({ day, month, year });    
                console.log(dateOfBirthIsValid);
                
        if (dateOfBirthIsValid) {    
          const dateofbirthx = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
          setdateOfBirthIsValid(true)
          setdateOfBirth(dateofbirthx)
        } else {
         setdateOfBirthIsValid(false)
        }
      };
    

    const Months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month, index) => (
            <option key={index} value={month}>

            {language.Next==='Next' ? monthNames[month - 1]:  monthNamessp[month - 1]}
            </option>
    ));

    const { yearDefault, years } = getYears(location.state.business.businessId);
    const Years = years.map((year, index) => (
        <option selected={yearDefault===year} key={index} value={year}>
          {year}
        </option>
      ));


      const NextfinishButton=async()=>{
        const site = location.state.site;
        const business = location.state.business;
        const user = location.state.user;
        const firstName= location.state.firstName;
        const lastName=location.state.lastName;
        const mobile =location.state.mobile;
        const zip=location.state.zip;
        const lang =location.state.language;
        const optOut=location.state.optOut;
        const path = '/'+business.path+'/'+site.path
        if(location.state.IsUpdating){
          setNextdisable(true)
          await UserAction('updateUser',{userId:location.state.userId,dateofbirth:dateOfBirth
        })
          location.state.userId = location.state.userId;
          location.state.IsUpdating=false;  
          location.state.IsUpdated=true; 
          setNextdisable(false)    
          navigate(path+'/thanks',location)
        }else{
          navigate(path+'/vehicles',{state:{mobile,user,business,site,firstName,lastName,zip,dateofbirth:dateOfBirth,language:lang,optOut,IsUpdating:false}})
        }
        
    }
    
    const restartCheckInfun=async()=>{
      setrestartCheckIn(true)
      const  error = { 
             associated_data:JSON.stringify('restart checkIn from dateofbirth screen'),
             class_name:'vehicle',
             error_message:'restart checkIn',
             error_type:'component error',
             exception_message:JSON.stringify('restart checkIn'),
             file_name:'dateofbirth.tsx',
             function_name:'dateofbirth-Component',
             }
       const errorInOperationx = await errorInOperation(error);
       if(errorInOperationx){
       const path = '/'+businesses.path+'/'+site.path  
       navigate(path,{state:{business:location.state.business,site:location.state.site}})
         }
 }
      
    return(
        businesses!==null && site!==null ?<div  className='main-container' style={{background:site.kioskUiBackgroundColor!==undefined && site.kioskUiBackgroundColor!==null &&   site.kioskUiBackgroundColor!=='' ? site.kioskUiBackgroundColor :  businesses.theme.background,height:'100vh'}}>
            <div className="">
            <ExternalCss/>
                <h1 className="enternameTitle ziptitle" style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>{language.dob}</h1>
                <button  disabled={restartCheckIn} style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>restartCheckInfun()} className='optoutbtn restartCheckinButton' >{restartCheckIn ? <ScaleLoader  width={10} height={15} color="#fff" /> :language.restartCheckIn}</button>
            </div>  

            <br/> <br/> <br/>      
    <div className='selectgroup '>
        <label className='dob-label-color' >
        {language.month}
            <select onChange={handleMonth}>
                <option  value={'null'}>{language.Selectmonth}</option>
                {Months}
            </select>
         </label>
         <label className='dob-label-color'>
           {language.day}
          <select onChange={(e)=>setday(e.target.value)} value={day}>
            <option value={'null'}>{language.selectDay}</option>
           {days}
          </select>
         </label>

         <label  className='dob-label-color'>
            {language.year}
            <select onChange={handleYear} value={year}>
                {Years}
            </select>
         </label>
        </div>
       {!dateOfBirthIsValid? <p className='dobmsg'>
        {language.agevalidation}
        </p>:''}
        <br/> <br/> <br/>
            <div style={{display:'flex'}} className="centerDivName">
            {routes.dateofbirth.optional===true && !IsUpdating ?   <button   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{language.NoThanks}</button>:''}
                
                    <button  disabled={!dateOfBirthIsValid || Nextdisable} style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{IsUpdating ? language.update :language.NextFinish}</button>

         </div>     
        </div>:<p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>
    )
    
    
}