import React,{ useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { strings } from "../actions/string";
import { ButtonGroup, Spinner } from "@blueprintjs/core";
import { errorInOperation } from "../kiosklog/ServerLogging";
import { ScaleLoader } from "react-spinners";
import { UserAction } from "../actions/urserAction";
import { ExternalCss } from "../components/ExternalCss";


export  const Optin=()=>{

    const location = useLocation();
    const routes=location.state.site.routes;
    const [businesses,setBusinesses]=useState(null as any)
    const [site,setsite]=useState(null as any)
    const [restartCheckIn,setrestartCheckIn]=useState(false)

    const navigate= useNavigate();
    const language  =location.state.language==='en' ? strings.en : strings.esp;
    const [continueOkay,setcontinueOkay]=useState(false)
    useEffect(()=>{
        setBusinesses(location.state.business)            
        setsite(location.state.site)   
        console.log(routes,'kkkkkkkk');
        NextfinishButton(null)
    //     if(routes.optin.enabled===false || (routes.optin.alwaysShow===false && location.state.user!==false  && location.state.IsUpdating===false) || location.state.optOut===true){
    //       NextfinishButton(null)
    //   } 
    })

const cssforbutton= businesses!==null && site!==null ? {margin:'20px',background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}:{};
 
    const NextfinishButton=async(optin:any)=>{
        const site = location.state.site;
        const business = location.state.business;
        const user = location.state.user;
        const firstName= location.state.firstName;
        const lastName=location.state.lastName;
        const mobile =location.state.mobile;
        const zip=location.state.zip;
        const dateofbirth= location.state.dateofbirth;
        const vehicles=location.state.vehicles;
        const mugshot=location.state.mugshot;
        const signature=location.state.signature;
        const customertype=location.state.customertype;
        const email=location.state.email;
        const lang=location.state.language;
        const optOut=location.state.optOut;
        const Supercard=location.state.Supercard;
        const path = '/'+business.path+'/'+site.path   
        
        if(location.state.IsUpdating){
            await UserAction('updateUser',{userId:location.state.userId,optin
          })
            location.state.userId = location.state.userId;
            location.state.IsUpdating=false;      
            navigate(path+'/thanks',location)
          }else{
        navigate(path+'/thanks',{state:{mobile,user,business,site,firstName,lastName,zip,dateofbirth,vehicles,mugshot,signature,customertype,email,Supercard,language:lang,optOut,optin,IsUpdating:false}})   
          }
    }
    
    const restartCheckInfun=async()=>{
        setrestartCheckIn(true)
        const  error = { 
               associated_data:JSON.stringify('restart checkIn from customerType screen'),
               class_name:'vehicle',
               error_message:'restart checkIn',
               error_type:'component error',
               exception_message:JSON.stringify('restart checkIn'),
               file_name:'customerType.tsx',
               function_name:'customerType-Component',
               }
         const errorInOperationx = await errorInOperation(error);
         if(errorInOperationx){
         const path = '/'+businesses.path+'/'+site.path  
         navigate(path,{state:{business:location.state.business,site:location.state.site}})
           }
   }

    return(
        businesses!==null && site!==null ?<div  className='main-container' style={{background:site.kioskUiBackgroundColor!==undefined && site.kioskUiBackgroundColor!==null &&   site.kioskUiBackgroundColor!=='' ? site.kioskUiBackgroundColor :  businesses.theme.background,height:'100vh'}}>
            <div className="">
                <ExternalCss/>
            <img src={businesses.theme.logo} style={{
                    width:site.widthForDesktop!==undefined ? site.widthForDesktop :'300px',
                    height:site.heightForDesktop!==undefined ? site.heightForDesktop :'300px',
                     borderRadius:'10px'
                    }}/>
                <h1 className="enternameTitle" style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>{language.optInHeader}</h1>
                <button  disabled={restartCheckIn} style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>restartCheckInfun()} className='optoutbtn restartCheckinButton' >{restartCheckIn ? <ScaleLoader  width={10} height={15} color="#fff" /> :language.restartCheckIn}</button>
            </div>  

        <div>                    
        <button  disabled={false} style={cssforbutton} onClick={()=>NextfinishButton(true)}  className='nextbuttoncss' >{language.Yes}</button>
        <button  disabled={false} style={cssforbutton} onClick={()=>NextfinishButton(false)}  className='nextbuttoncss' >{language.No}</button>
        </div>  

                <div className="centerDiv"  style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>
                    {language.disclaimer}
                </div>   
        </div>:<p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>
    )
    
}