import { useLocation, useNavigate } from "react-router-dom"

import React, { useEffect, useState } from 'react';
import { H1, Spinner } from "@blueprintjs/core";
import { ScaleLoader } from "react-spinners";
import { Keypads } from "../components/keypads";
import { errorInOperation } from "../kiosklog/ServerLogging";
import { strings } from "../actions/string";
import { UserAction } from "../actions/urserAction";
import { ExternalCss } from "../components/ExternalCss";
import { firebase } from "../global/firebase";
import { collection, doc, setDoc } from "firebase/firestore";

export const Name=()=>{   
   
const location = useLocation();
const routes=location.state.site.routes;
const successborder = location.state.business!==null && location.state.business.theme!==undefined && location.state.business.theme.colors!==undefined && location.state.business.theme.colors.success!==undefined ? location.state.business.theme.colors.success : 'rgb(48, 158, 42)'
const [businesses,setBusinesses]=useState(null as any)
const [IsUpdating,setIsUpdating]=useState(location.state.IsUpdating)
const [site,setsite]=useState(null as any)
const [restartCheckIn,setrestartCheckIn]=useState(false)
const [Nextdisable,setNextdisable]=useState(true)
const [focusFirstInput,setfocusFirstInput]=useState(true)
const [focusLastInput,setfocusLastInput]=useState(false)
const [firstName,setfirstName]=useState('')
const [lastName,setlastName]=useState('')
const [uppercaseset,settuppercase]=useState(true)
const navigate= useNavigate();
const language  =location.state.language==='en' ? strings.en : strings.esp;
useEffect(()=>{
    setBusinesses(location.state.business)            
    setsite(location.state.site)
    console.log(location.state.site);
    console.log( location.state.user,'kkkk');
    
    if(routes.name.enabled===false || (location.state.user!==false && location.state.IsUpdating===false)){
        NextfinishButton()
    }
   
})
// function capitalizeFirstLetter(string:any) {
//     return string.charAt(0).toUpperCase() + string.slice(1);
// }
const handleKeypadButton=(e:any)=>{
    if(focusFirstInput){
        setfirstName(firstName+e.target.value)        
        disablenextbutton(firstName+e.target.value,lastName)
        if((firstName+e.target.value).length<=0){
            settuppercase(true)
        }else{
            settuppercase(false)
        }
      }else if(focusLastInput){
       setlastName(lastName+e.target.value)
       disablenextbutton(firstName,lastName+e.target.value)
       if((lastName+e.target.value).length<=0){
        settuppercase(true)
    }else{
        settuppercase(false)
    }
      } 
  
}


const disablenextbutton=(firstName:string,lastName:string)=>{
    if(firstName!=='' && lastName!=='' ){
        setNextdisable(false)
      }else{
        setNextdisable(true)   
      } 
}

const onHandleKeypadClick=()=>{
    if(focusFirstInput){
        const firstNamex = firstName.slice(0, -1);
        
        setfirstName(firstNamex)
        disablenextbutton(firstNamex,lastName);
        if((firstNamex).length<=0){
            settuppercase(true)
        }else{
            settuppercase(false)
        }
      }else if(focusLastInput){
        const lastNamex = lastName.slice(0, -1);
       setlastName(lastNamex)
       disablenextbutton(firstName,lastNamex);
       if((lastNamex).length<=0){
        settuppercase(true)
    }else{
        settuppercase(false)
    }
      }

}

const NextfinishButton=async()=>{

    const site = location.state.site;
    const business = location.state.business;
    const user = location.state.user;
    const mobile =location.state.mobile;
    const lang =location.state.language;
    const optOut=location.state.optOut;
    const path = '/'+business.path+'/'+site.path
    if(location.state.IsUpdating){
        setNextdisable(true)
        console.log(location.state.userId);
        const name ={
            displayName:firstName+' '+lastName,
            firstname:firstName,
            lastname:lastName,
            }
       await UserAction('updateUser',{userId:location.state.userId,name
      })

      try {
      const data =   {userId:location.state.userId,name}            
    await setDoc(doc(collection(firebase, "userUpdateLog"),data.userId),{site:site.siteId,business:business.businessId,newdata:data,lastUpdatedDate:new Date()})
        

    } catch (error) {
       console.log(error);
        
    }

    location.state.userId = location.state.userId;
    location.state.IsUpdating=false; 
    location.state.IsUpdated=true; 
    setNextdisable(false)     
    navigate(path+'/thanks',location)
    }else{
    navigate(path+'/zip',{state:{mobile,user,business,site,firstName,lastName,language:lang,optOut,IsUpdating:false}})
    }
    
}

const restartCheckInfun=async()=>{
     setrestartCheckIn(true)
     const  error = { 
            associated_data:JSON.stringify('restart checkIn from name screen'),
            class_name:'Name',
            error_message:'restart checkIn',
            error_type:'component error',
            exception_message:JSON.stringify('restart checkIn'),
            file_name:'name.tsx',
            function_name:'name-Component',
            }
      const errorInOperationx = await errorInOperation(error);
      if(errorInOperationx){
      const path = '/'+businesses.path+'/'+site.path  
      navigate(path,{state:{business:location.state.business,site:location.state.site}})
        }
}
    return(
    businesses!==null && site!==null ? <div className='main-container namepage' style={{background:site.kioskUiBackgroundColor!==undefined && site.kioskUiBackgroundColor!==null &&  site.kioskUiBackgroundColor!=='' ? site.kioskUiBackgroundColor :  businesses.theme.background,height:'100vh'}}>
                    <div className="">
                    <ExternalCss/>
                        <h1 className="enternameTitle" style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>{language.pleaseenteryourname}:</h1>
                        <button  disabled={restartCheckIn} style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>restartCheckInfun()} className='optoutbtn restartCheckinButton' >{restartCheckIn ? <ScaleLoader  width={10} height={15} color="#fff" /> :language.restartCheckIn}</button>
                    </div>
                    <div className="inputgroup">
                       
                    <input
                    style={{boxShadow: firstName!=='' ? `black 1px 2px 1px 1px, rgb(68, 68, 68) 1px 2px 0px 6px, white 1px 2px 0px 5px, ${successborder} 0px 0px 3px 4px inset`:''   
                    }}
                    onClick={()=>{setfocusFirstInput(true); setfocusLastInput(false); firstName.length<=0 ?  settuppercase(true) :  settuppercase(false)}} value={firstName}  readOnly={true} className={focusFirstInput ? "nameInput focusInput" : "nameInput"}
                     placeholder={location.state.language!=='en' && window.location.href.includes('u-pick-it') ? 'Nombre' : language.firstName}
                      type="text"/>
                    <input
                       style={{boxShadow: lastName!=='' ? `black 1px 2px 1px 1px, rgb(68, 68, 68) 1px 2px 0px 6px, white 1px 2px 0px 5px, ${successborder}  0px 0px 3px 4px inset`:''                                              }}
                    onClick={()=>{setfocusFirstInput(false); setfocusLastInput(true);  lastName.length<=0 ?  settuppercase(true) :  settuppercase(false)}}  value={lastName} readOnly={true} className={focusLastInput ? "nameInput focusInput" : "nameInput"} placeholder={language.lastName} type="text"/>
                    </div>
                   
                    <Keypads
                    focusFirstInput={(e:any)=>{setfocusFirstInput(true); setfocusLastInput(false); 
                        firstName.length<=0 ?  settuppercase(true) :  settuppercase(false)
                    }}
                    focusLastInput={(e:any)=>{setfocusFirstInput(false); setfocusLastInput(true);
                        lastName.length<=0 ?  settuppercase(true) :  settuppercase(false)
                    }}
                    disable={false}
                    focusFirstInputVar={focusFirstInput}
                    focusLastInputVar={focusLastInput}
                    type="name"
                    capital={uppercaseset}
                    handleKeypadButton={(e:any)=>handleKeypadButton(e)}
                    onHandleKeypadClick={()=>onHandleKeypadClick()}
                    business={businesses}
                    disabledArrow={lastName==='' && firstName===''}
                    site={site}/>
                        <br/>
                    <div style={{display:'flex'}} className="centerDivName">
          {routes.name.optional===true && !IsUpdating ?  <button   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{language.NoThanks}</button>:''}
                 {routes.name.optional===true && !IsUpdating ? '   ' :''}
                    <button  disabled={Nextdisable} style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{IsUpdating ? language.update :language.NextFinish}</button>
                    </div>
     </div>: <p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>)
             
}