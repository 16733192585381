
import React from 'react';

export const ExternalCss=()=>{

    return (
        <div>
    {window.location.href.includes('wilberts-u-pull-it') ?   <link rel="stylesheet" type="text/css" href="/wilbert.css" /> : ''}
    {window.location.href.includes('bessler') ?   <link rel="stylesheet" type="text/css" href="/bessler.css" /> : ''}
    </div>
    )
}